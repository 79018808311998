<template>
<div class="page">
    <div class="relative">
        <Header></Header>
        <PageImage :image="image" :title="title"></PageImage>        
    </div>
    <div class="page-content">
        <slot></slot>
    </div>
    <Footer></Footer>
</div>
</template>

<script>
const PageImage = () => import("@/components/page/PageImage");
const Header = () => import("@/components/common/Header");
const Footer = () => import("@/components/common/Footer");

export default {
    name: "Page",
    props: {
        "image": String,
        "title": String,
    },
    components: {
        PageImage,
        Header,
        Footer
    },
};
</script>
